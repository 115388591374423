import { NaniumObject, Type } from "nanium/objects";
import { AddressType } from "./types/address.type";
import dayjs from "dayjs";

export class Gathering extends NaniumObject<Gathering> {
  static strictDefault: boolean = true;

  @Type(String) id?: string;
  @Type(String) name?: string;
  @Type(String) description?: string;

  @Type(AddressType) alternativeAddress?: AddressType;

  @Type(String) startingAt?: string;
  @Type(String) endingAt?: string;
  @Type(Boolean) isPeriodically?: boolean;
  @Type(String) rythm?: string;

  @Type(String) titlePicture?: string;
  @Type(String) titleVideo?: string;

  @Type(String) covidRegulations?: string;
  @Type(String) externalLinkToEvent?: string;

  @Type(String) owningFacility?: string;

  @Type(Number) viewsLast14Days?: number;
  @Type(Number) viewsPrevious14Days?: number;
  @Type(Number) viewsLast365Days?: number;

  isMultiDayEvent(): boolean {
    return dayjs(this.startingAt).format('DD.MM.YYYY') !== dayjs(this.endingAt).format('DD.MM.YYYY');
  }
}
