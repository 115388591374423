import { RequestType } from 'nanium/objects';
import { ServiceRequestBase } from '../serviceRequestBase';
import { Facility } from "../../entities/facility.entity";

@RequestType({
	responseType: Boolean,
	genericTypes: { TRequestBody: Facility },
	scope: 'public'
})
export class FacilityUpdateRequest extends ServiceRequestBase<Facility, boolean> {
	static serviceName: string = 'NaniumDemo:facility/update';
}
