import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { User } from "../../../../server/entities/user.entity";
import {
  BaseSecurityUserHasPermissionRequest
} from "../../../../server/services/base/security/userHasPermission.contract";
import { userPermissions } from "../../../../server/entities/user-role.entity";

@Injectable({
  providedIn: 'root'
})
export class WebAuthenticationService implements CanActivate {

  user$: BehaviorSubject<User | undefined> = new BehaviorSubject<User | undefined>(undefined);
  urlBeforeLogin?: string;

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean|UrlTree> {
    const user = await this.getUser();
    if (!user) {
      //this.urlBeforeLogin = route.url;
      return this.router.parseUrl('/login');
    } else {
      return true;
    }
  }

  private token?: string;

  constructor(
    private storage: Storage,
    private router: Router,
  ) {
  }

  async init(): Promise<void> {
    await this.storage.create().then();
  }

  get isLoggedIn(): boolean {
    return !!this.user$.getValue();
  }

  async getUser(): Promise<User | undefined> {
    await this.storage.create();
    if (!this.user$.getValue()) {
      const data = await this.storage.get('user');
      if (data) {
        this.user$.next(new User(data));
      }
    }
    return this.user$.getValue();
  }

  async setUser(value: User | undefined) {
    this.user$.next(value);
    await this.storage.set('user', value).then();
  }

  async getToken(): Promise<string | undefined> {
    return this.token ?? ( this.token = (await this.storage.get('token')) as any as string);
  }

  async setToken(value: string | undefined) {
    this.token = value;
    await this.storage.set('token', value).then();
  }

  async logout() {
    await this.setToken(undefined);
    await this.setUser(undefined)
    await this.router.navigateByUrl('login');
  }

  public async hasAdminAccess(): Promise<boolean> {
    return await new BaseSecurityUserHasPermissionRequest({
      permission: userPermissions.accessAdminBackend,
      userId: (await this.getUser()).id
    }).execute();
  }

  public async hasCreatorAccess(): Promise<boolean> {
    return await new BaseSecurityUserHasPermissionRequest({
      permission: userPermissions.accessCreatorBackend,
      userId: (await this.getUser()).id
    }).execute();
  }
}
