import { NaniumObject, Type } from "nanium/objects";
import { AddressType } from "./types/address.type";
import { OpeningHoursType } from "./types/openingHours.type";
import { GeoLocationType } from "./types/geoLocation.type";
import {ClubTag} from "./types/facility/ClubTag";
import {ConsultingTag} from "./types/facility/ConsultingTag";

export class Facility extends NaniumObject<Facility> {
  static strictDefault: boolean = true;

  @Type(String) id?: string;
  @Type(String) name?: string;
  @Type(String) description?: string;

  @Type(AddressType) address?: AddressType;
  @Type(GeoLocationType) location?: GeoLocationType;

  @Type(OpeningHoursType) openingHours?: OpeningHoursType;
  @Type(String) openingHoursUnformatted?: string;
  @Type(String) phoneNumber?: string;
  @Type(String) contactMail?: string;
  @Type(String) carrier?: string;
  @Type(String) website?: string;

  @Type(Array, String) pictures?: string;
  @Type(String) titlePicture?: string;
  @Type(String) logo?: string;
  @Type(Array, String) videos?: string[];

  // CLUBS
  @Type(Boolean) isClub?: boolean;
  @Type(ClubTag) clubTags?: ClubTag;
  @Type(Array, String) participationOffers?: string[];

  // CONSULTING FACILITIES
  @Type(Boolean) isConsulting?: boolean;
  @Type(ConsultingTag) consultingTags?: ConsultingTag;
  @Type(Array, String) consultingOffers?: string[];

  // TEMPORARY
  @Type(Number) distanceFromUser?: number;

  @Type(Number) viewsLast14Days?: number;
  @Type(Number) viewsPrevious14Days?: number;
  @Type(Number) viewsLast365Days?: number;
}
