import { Injectable } from '@angular/core';
import { ServiceRequestInterceptor } from 'nanium/interfaces/serviceRequestInterceptor';
import { Router } from '@angular/router';
import { ServiceRequestBase } from "../../../../server/services/serviceRequestBase";
import { WebAuthenticationService } from "./web-authentication.service";

@Injectable({
	providedIn: 'root'
})
export class WebRequestInterceptorService implements ServiceRequestInterceptor<any> {
   constructor(
    private router: Router,
    private datacenterAuth: WebAuthenticationService
  ) {
  }

	async execute(request: ServiceRequestBase<any, any>): Promise<ServiceRequestBase<any, any> | undefined> {
    if (
      request.head &&
      (!request.head.user || !request.head.password) &&
      !this.datacenterAuth.isLoggedIn
    ) {
      this.datacenterAuth.urlBeforeLogin = this.router.url !== '/login' ? this.router.url : undefined;
      await this.router.navigate(['/login']);
      return undefined;
    }
    request.head = request.head || {};
    if (!request.head.user && !request.head.password) {
      request.head.token = await this.datacenterAuth.getToken();
    }
    // @ts-ignore
    request.head.language = navigator.language || navigator['userLanguage'];
    request.head.timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

    return request;
	}
}
