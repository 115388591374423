import { NaniumObject, RequestType, Type } from 'nanium/objects';
import { ServiceRequestBase } from '../../serviceRequestBase';

export class BaseSecurityUserHasPermissionRequestBody extends NaniumObject<BaseSecurityUserHasPermissionRequestBody> {
	@Type(String) userId?: string;
	@Type(String) permission: string;
}

@RequestType({
	responseType: Boolean,
	genericTypes: { TRequestBody: BaseSecurityUserHasPermissionRequestBody },
	scope: 'public'
})
export class BaseSecurityUserHasPermissionRequest extends ServiceRequestBase<BaseSecurityUserHasPermissionRequestBody, boolean> {
	static serviceName: string = 'NaniumDemo:base/security/userHasPermission';
}
