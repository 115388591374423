import { NaniumObject, RequestType, Type } from 'nanium/objects';
import { ServiceRequestBase } from '../serviceRequestBase';
import { User } from "../../entities/user.entity";

export class UserQueryRequestBody extends NaniumObject<UserQueryRequestBody> {
	@Type(Number) id?: number;
	@Type(String) username?: string;
	@Type(Object) likedFacilities?: object;
}

@RequestType({
	responseType: User,
	genericTypes: { TRequestBody: UserQueryRequestBody },
	scope: 'public'
})
export class UserQueryRequest extends ServiceRequestBase<UserQueryRequestBody, User[]> {
	static serviceName: string = 'NaniumDemo:user/query';
}
