import {NaniumObject, RequestType, Type} from 'nanium/objects';
import { ServiceRequestBase } from '../serviceRequestBase';
import { News } from "../../entities/news.entity";

export class NewsQueryRequestBody extends NaniumObject<NewsQueryRequestBody> {
	@Type(Object) filter?: any;
	@Type(Boolean) includeAnalytics?: boolean;

	//deprecated
	@Type(Object) owningFacility?: any;
}

@RequestType({
	responseType: News,
	genericTypes: { TRequestBody: NewsQueryRequestBody },
	scope: 'public',
	skipInterceptors: true
})
export class NewsQueryRequest extends ServiceRequestBase<NewsQueryRequestBody, News[]> {
	static serviceName: string = 'NaniumDemo:news/query';
}
