import { RequestType } from 'nanium/objects';
import { ServiceRequestBase } from '../serviceRequestBase';
import { Gathering } from "../../entities/gathering.entity";

@RequestType({
	responseType: Boolean,
	genericTypes: { TRequestBody: Gathering },
	scope: 'public'
})
export class GatheringUpdateRequest extends ServiceRequestBase<Gathering, boolean> {
	static serviceName: string = 'NaniumDemo:gathering/update';
}
