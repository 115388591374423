import { RequestType } from 'nanium/objects';
import { ServiceRequestBase } from '../serviceRequestBase';
import { News } from "../../entities/news.entity";

@RequestType({
	responseType: Boolean,
	genericTypes: { TRequestBody: News },
	scope: 'public'
})
export class NewsUpdateRequest extends ServiceRequestBase<News, boolean> {
	static serviceName: string = 'NaniumDemo:news/update';
}
