import {NaniumObject, RequestType, Type} from 'nanium/objects';
import { ServiceRequestBase } from '../serviceRequestBase';
import { Gathering } from "../../entities/gathering.entity";

export class GatheringQueryRequestBody extends NaniumObject<GatheringQueryRequestBody> {
	@Type(Boolean) includeFacility?: boolean = false;
	@Type(Object) filter?: any;

	//deprecated
	@Type(String) owningFacility?: string;
}

@RequestType({
	responseType: Gathering,
	genericTypes: { TRequestBody: GatheringQueryRequestBody },
	scope: 'public',
	skipInterceptors: true
})
export class GatheringQueryRequest extends ServiceRequestBase<GatheringQueryRequestBody, Gathering[]> {
	static serviceName: string = 'NaniumDemo:gathering/query';
}
