import { NaniumObject, Type } from "nanium/objects";

export const userRoles = {
  admin: 'admin',
  creator: 'creator',
  user: 'user',
}

export const userPermissions = {
  accessApp: 'accessApp',
  accessCreatorBackend: 'accessCreatorBackend',
  accessAdminBackend: 'accessAdminBackend',

  accessAdminSettings: 'accessAdminSettings',

  viewAllContent: 'viewAllContent',
  manageAllContent: 'manageAllContent',

  viewSelfContent: 'viewSelfContent',
  manageSelfContent: 'manageSelfContent',

  viewPublicContent: 'viewPublicContent'
}

export class UserRole extends NaniumObject<UserRole> {
  static strictDefault: boolean = true;

  @Type(String) id?: string;
  @Type(String) role?: string;

  @Type(Array, String) permissions?: string[];

  public static collectionName(): string {
    return 'user-role';
  }
}
