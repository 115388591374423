import { NaniumObject, Type } from "nanium/objects";

export class AddressType extends NaniumObject<AddressType> {
  @Type(String) street?: string;
  @Type(String) postCode?: string;
  @Type(String) city?: string;

  /* @deprecated */
  @Type(String) number?: string;
}
