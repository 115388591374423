import { NaniumObject, Type } from "nanium/objects";
import { OpeningHoursOpeningType } from "./openingHours/opening.type";

export class OpeningHoursType extends NaniumObject<OpeningHoursType> {
  @Type(Array, OpeningHoursOpeningType) monday?: OpeningHoursOpeningType[];
  @Type(Array, OpeningHoursOpeningType) tuesday?: OpeningHoursOpeningType[];
  @Type(Array, OpeningHoursOpeningType) wednesday?: OpeningHoursOpeningType[];
  @Type(Array, OpeningHoursOpeningType) thursday?: OpeningHoursOpeningType[];
  @Type(Array, OpeningHoursOpeningType) friday?: OpeningHoursOpeningType[];
  @Type(Array, OpeningHoursOpeningType) saturday?: OpeningHoursOpeningType[];
  @Type(Array, OpeningHoursOpeningType) sunday?: OpeningHoursOpeningType[];
}
