import { RequestType } from 'nanium/objects';
import { ServiceRequestBase } from '../serviceRequestBase';
import {User} from "../../entities/user.entity";

@RequestType({
	responseType: Boolean,
	genericTypes: { TRequestBody: User },
	scope: 'public'
})
export class UserUpdateRequest extends ServiceRequestBase<User, boolean> {
	static serviceName: string = 'NaniumDemo:user/update';
}
