import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { WebAuthenticationService } from "../services/security/web-authentication.service";

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then( m => m.AdminPageModule),
    canActivate: [WebAuthenticationService],
  },
  {
    path: 'login',
    loadChildren: () => import('./user/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'opening-hours-input',
    loadChildren: () => import('./util/opening-hours-input/opening-hours-input.module').then( m => m.OpeningHoursInputPageModule)
  },
  {
    path: 'image-upload',
    loadChildren: () => import('./util/image-upload/image-upload.module').then( m => m.ImageUploadPageModule)
  },
  {
    path: 'address-input',
    loadChildren: () => import('./util/address-input/address-input.module').then( m => m.AddressInputPageModule)
  },
  {
    path: 'start-to-end-input',
    loadChildren: () => import('./util/date/start-to-end-input/start-to-end-input.module').then( m => m.StartToEndInputPageModule)
  },
  {
    path: 'import-facilities',
    loadChildren: () => import('./util/import-facilities/import-facilities.module').then( m => m.ImportFacilitiesPageModule)
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./user/onboarding/onboarding.module').then( m => m.OnboardingPageModule)
  },
  {
    path: 'reset-password/:token',
    loadChildren: () => import('./user/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./user/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  providers: [WebAuthenticationService],
  exports: [RouterModule]
})
export class AppRoutingModule {}
