import {Type} from "nanium/objects";

export class ConsultingTag {
    // A - Beratung bei Suizidgedanken oder selbst-verletzendem Verhalten
    @Type(Boolean) tagSuicidePrevention?: boolean;
    // B - Ich bin depressiv / niedergeschlagen oder habe andauernd Angst
    @Type(Boolean) tagPsychological?: boolean;
    // C - Ich habe Angst, wenn ich allein unterwegs bin
    @Type(Boolean) tagFear?: boolean;
    // D - Demütigung, Ausgrenzung und Benachteiligung
    @Type(Boolean) tagHumiliation?: boolean;
    // E - Erfahrung oder Beobachtung verbaler Angriffe im sozialen Raum - Sexismus, Belästigung, Erniedrigung
    @Type(Boolean) tagVictim?: boolean;
    // F - Angst vor, oder die Erfahrung von Gewalt, Übergriffigkeit oder Missbrauch
    @Type(Boolean) tagCrisis?: boolean;
    // G - Gewalterfahrungen im digitalen Raum oder durch Aufnahme-Erstellungen
    @Type(Boolean) tagCyberBullying?: boolean;
    // H - Ich habe Angst davor, jemand anderes zu verletzen oder zu schaden
    @Type(Boolean) tagViolence?: boolean;
    // I - Antidemokratische und menschenfeindliche Einstellungen Freundes- und Bekanntenkreis
    @Type(Boolean) tagDemocracy?: boolean;
    // J - Fragen zu sexuell übertragbaren Infektionen, Coming-out, Körper und Gesundheit, Schutz und Verhütung, bei Partner- und Sexualproblemen
    @Type(Boolean) tagQueer?: boolean;
    // K - Schwangerschafts- und Konfliktberatung
    @Type(Boolean) tagSexCounseling?: boolean;
    // L - Sucht, (Drogen-)Konsum und Essstörung
    @Type(Boolean) tagAddiction?: boolean;
    // M - Behinderungen und Krankheit in der Familie
    @Type(Boolean) tagDisability?: boolean;
    // N - Probleme mit Geschwistern, Eltern und Vormündern
    @Type(Boolean) tagFamily?: boolean;
    // O - Schulangst und Prüfungsangst
    @Type(Boolean) tagSchool?: boolean;
    // P - Redebedarf, Liebeskummer, Stress, Traurigkeit, Langeweile, Einsamkeit
    @Type(Boolean) tagLife?: boolean;
    // Q - Trauerhilfe
    @Type(Boolean) tagGrief?: boolean;
    // R - Gefängnisstrafen von Angehörigen
    @Type(Boolean) tagPrison?: boolean;
    // S - Flucht, Einbürgerung und Integration
    @Type(Boolean) tagMigration?: boolean;
    // T - Wohnungslosigkeit oder drohende Wohnungslosigkeit
    @Type(Boolean) tagHomeless?: boolean;
    // U - Finanz- und Schuldenberatung
    @Type(Boolean) tagFinancial?: boolean;
    // V - Übergang von Schule zu Beruf
    @Type(Boolean) tagJob?: boolean;
    // W - Auslandsjahr oder Auslandssemester
    @Type(Boolean) tagAbroad?: boolean;
    // X - Studiums-Beratung
    @Type(Boolean) tagStudy?: boolean;
    // Y - Care-Leaving, Studium und Ausbildung nach der stationären Hilfe
    @Type(Boolean) tagCareLeaving?: boolean;
}
