import { Type } from "nanium/objects";

export class ClubTag {
    @Type(Number) tagOutdoorAdventure?: number;
    @Type(Number) tagSport?: number;
    @Type(Number) tagTheater?: number;
    @Type(Number) tagMedia?: number;
    @Type(Number) tagEnvironment?: number;
    @Type(Number) tagMusic?: number;
    @Type(Number) tagArts?: number;
    @Type(Number) tagJobs?: number;
    @Type(Number) tagInternationalExchange?: number;
    @Type(Number) tagMigration?: number;
}
