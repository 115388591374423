import { NaniumObject, Type } from "nanium/objects";
import {
  BaseSecurityGeneratePasswordRequest,
  BaseSecurityGeneratePasswordResponse
} from "../services/base/security/generatePassword.contract";
import { AddressType } from "./types/address.type";
import { userRoles } from "./user-role.entity";
import {GeoLocationType} from "./types/geoLocation.type";

export class User extends NaniumObject<User> {
  static strictDefault: boolean = true;

  @Type(String) id?: string;

  @Type(String) private _roles: string[];

  @Type(String) username?: string;
  @Type(String) email?: string;
  @Type(String) region?: string;
  @Type(GeoLocationType) location?: GeoLocationType;

  @Type(String) profilePicture?: string;

  @Type(String) password?: string;
  @Type(Date) passwordExpirationDate?: Date;
  @Type(String) passwordResetToken?: string;

  @Type(Boolean) activated?: boolean;
  @Type(Date) registeredAt?: Date;
  @Type(Date) lastLoginAt?: Date;

  // GDPR == DSGVO
  @Type(Boolean) acceptedGDPR;
  // GTC == AGB
  @Type(Boolean) acceptedGTC;
  @Type(Boolean) userNeedsOnboardingFlow;

  @Type(Date) birthday?: Date;
  // ideally the user provides his location to our app, if not fall back to this address
  @Type(AddressType) addressFallback?: AddressType;

  @Type(Array, String) likedFacilities?: string[];
  @Type(Array, String) ownedFacilities?: string[];

  public static collectionName(): string {
    return 'user';
  }

  public async updatePassword(password: string): Promise<void> {
    this.password = await User.generatePassword(password);
  }

  public static async generatePassword(password: string): Promise<string> {
     const response: BaseSecurityGeneratePasswordResponse
       = await new BaseSecurityGeneratePasswordRequest({rawPassword: password}).execute();
     if (response.encryptedPassword) {
       return response.encryptedPassword;
     }
     throw Error('Couldn\'t generate password.');
  }

  public get roles() {
    if (!this._roles) {
      this._roles = [];
    }
    return this._roles;
  }

  public addRole(role: string) {
    if (!this.roles.includes(role)) {
      this.roles.push(role);
    }
  }

  public isAdmin() {
    return this.roles.includes(userRoles.admin);
  }

  public isCreator() {
    return this.roles.includes(userRoles.creator);
  }
}
