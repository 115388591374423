import { NaniumObject, RequestType, Type } from 'nanium/objects';
import { ServiceRequestBase } from '../../serviceRequestBase';

export class BaseSecurityGeneratePasswordRequestBody extends NaniumObject<BaseSecurityGeneratePasswordRequestBody> {
	@Type(String) rawPassword?: string;
}

export class BaseSecurityGeneratePasswordResponse extends NaniumObject<BaseSecurityGeneratePasswordResponse> {
	@Type(String) encryptedPassword?: string;
}

@RequestType({
	responseType: BaseSecurityGeneratePasswordResponse,
	genericTypes: { TRequestBody: BaseSecurityGeneratePasswordRequestBody },
	scope: 'private'
})
export class BaseSecurityGeneratePasswordRequest extends ServiceRequestBase<BaseSecurityGeneratePasswordRequestBody, BaseSecurityGeneratePasswordResponse> {
	static serviceName: string = 'NaniumDemo:base/security/generatePassword';
}
