import { NaniumObject, RequestType, Type } from 'nanium/objects';
import { ServiceRequestBase } from '../serviceRequestBase';
import { Facility } from "../../entities/facility.entity";

export class FacilityGetRequestBody extends NaniumObject<FacilityGetRequestBody> {
	@Type(String) id?: string;
	@Type(Boolean) includeAnalytics?: boolean = false;
}

@RequestType({
	responseType: Facility,
	genericTypes: { TRequestBody: FacilityGetRequestBody },
	scope: 'public',
	skipInterceptors: true
})
export class FacilityGetRequest extends ServiceRequestBase<FacilityGetRequestBody, Facility> {
	static serviceName: string = 'NaniumDemo:facility/get';
}
